<template>
  <div class="modal modal--large" id="modal-default" v-if="open">
    <div class="modal__dialog modal-dialog-centered" style="position: relative;">
      <div class="modal__content">
        <a class="modal__close" v-on:click="handleModal">
            <span class="icon-close"></span>
        </a>
        <div class="modal__body">
          <div class="col-15 col-lg-9 col-xl-11">
            <div class="section">
              <NotificationsBox
                :notifications="notificationsTmp" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import NotificationsBox from '@/components/databoxes/NotificationsBox.vue';
import { toRef } from 'vue';

export default {
  name: 'CustomerNotifications',
  props: {
    notifications: Array,
  },
  setup(props) {
    const notificationsTmp = toRef(props, 'notifications');
    return {
      notificationsTmp,
    };
  },
  data() {
    return {
      open: false,
    };
  },
  async created() {
  },
  methods: {
    handleModal() {
      this.open = !this.open;
      this.$emit('refresh');
    },
  },
  components: {
    NotificationsBox
  },
};
</script>
