<template>
  <DialogWrapper
    :show="showModalAddEditContact"
    :header="`${contactEdit ? 'Edit' : 'Add'} Contact`">
    <AdaptifyOverlay :showOverlay="this.showOverlay"/>
    <ToastSubmit
      :toastData="toast"
      v-if="toast.showToast" />
    <span class="row">
      <span class="col-6">
        <InputErrorWrapper
          property="lastName"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="contact.lastName" />
            <label>Last Name</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-6">
        <InputErrorWrapper
          property="firstName"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="contact.firstName" />
            <label>First Name</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <span class="row">
      <span class="col-10">
        <InputErrorWrapper
          property="phoneNumber"
          :validate="validate">
          <div class="form-group__text">
              <input
                type="tel"
                v-model="formattedPhoneNumber"
                v-phone-format="contact.phoneNumber" />
              <label>Phone Number</label>
            </div>
            <small>Enter a 10 digit the phone number For example: (415) 555‑0132</small>
        </InputErrorWrapper>
      </span>
      <span class="col-2">
        <div class="subheader">Active</div>
        <label class="switch">
          <input
            type="checkbox"
            v-model="contact.active" />
          <span class="switch__input" />
        </label>
      </span>
    </span>
    <InputErrorWrapper
      property="address"
      :validate="validate">
      <div class="form-group__text">
        <input
          type="text"
          v-model="contact.address" />
        <label>Address</label>
      </div>
    </InputErrorWrapper>
    <InputErrorWrapper
      property="emailAddress"
      :validate="validate">
      <div class="form-group__text">
        <input
          type="text"
          v-model="contact.emailAddress" />
        <label>Email Address</label>
      </div>
    </InputErrorWrapper>
    <span class="row">
      <span class="col-4">
        <InputErrorWrapper
          property="city"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="contact.city" />
            <label for="firstname">City</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-4">
        <InputErrorWrapper
          property="zipCode"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="contact.zipCode" />
            <label>ZipCode</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-4">
        <InputErrorWrapper
          property="state"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="contact.state" />
            <label>State</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <AddEditDeleteButtons
      type="Contact"
      :add="() => handleSubmit(addEditContact)"
      :dataEdit="contactEdit"
      :update="() => handleSubmit(() => addEditContact(contactEdit.contactId))"
      :remove="() => handleSubmit(() => deleteContact(contactEdit.contactId))" />
  </DialogWrapper>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ToastSubmit from './ToastSubmit.vue';
import DialogWrapper from './DialogWrapper.vue';
import InputErrorWrapper from './InputErrorWrapper.vue';
import AddEditDeleteButtons from './AddEditDeleteButtons.vue';
import AdaptifyOverlay from '../../commons/AdaptifyOverlay.vue';
import { required, email, alpha } from '@vuelidate/validators';

export default {
  props: {
    customer: Object,
    refresh: Function,
    contactEdit: Object,
    showModalAddEditContact: Boolean,
  },
  data() {
    return {
      showOverlay: false,
      contact: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        address: null,
        city: null,
        state: null,
        zipCode: null,
        active: true,
        emailAddress: null,
      },
      rules: {
        firstName: { required, alpha },
        lastName: { required, alpha },
        phoneNumber: { required },
        address: { required },
        city: { required },
        state: { required },
        zipCode: { required },
        emailAddress: { required, email },
      },
      validate: null,
    };
  },
  async created() {
    if (this.contactEdit !== null) {
      this.contact = this.clone(this.contactEdit);
      this.formattedPhoneNumber = this.contact.phoneNumber;
    }
  },
  computed: {
    formattedPhoneNumber: {
      get() {
        if (this.contact.phoneNumber) {
          let value = this.contact.phoneNumber.replace(/\D/g, '');
          if (value.length > 0) {
            value = `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6, 10)}`;
          }
          return value;
        }
        return '';
      },
      set(value) {
        this.contact.phoneNumber = value.replace(/\D/g, '');
      }
    }
  },
  directives: {
    'phone-format': {
      bind(el, binding, vnode) {
        el.addEventListener('input', (event) => {
          let value = event.target.value.replace(/\D/g, '');
          if (value.length > 0) {
            value = `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6, 10)}`;
          }
          vnode.context[binding.expression] = value.replace(/\D/g, '');
          event.target.value = value;
        });
      },
      update(el, binding) {
        let value = binding.value.replace(/\D/g, '');
        if (value.length > 0) {
          value = `+${value.substring(0, 1)} (${value.substring(1, 4)}) ${value.substring(4, 7)}-${value.substring(7, 11)}`;
        }
        el.value = value;
      }
    }
  },
  methods: {
    addEditContact(id) {
      this.toast.showToast = false;
      const body = this.clone(this.customer);
      if (id) {
        const oldContact = body.contacts.find((contact) => contact.contactId === id);
        const index = body.contacts.indexOf(oldContact);
        body.contacts.splice(index, 1, this.contact);
      } else {
        body.contacts.push(this.contact);
      }
      this.$http
        .put(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/`, body)
        .then(() => {
          this.showOverlay = false;
          this.toastSuccess('Contacts updated successfully!');
          this.$emit('refresh');
        })
        .catch((error) => {
          this.showOverlay = false;
          this.toastError(error, 'Unable to update contacts.')
        });
    },
    deleteContact(id) {
      this.showOverlay = true;
      this.toast.showToast = false;
      const body = this.clone(this.customer);
      const deleteContact = body.contacts.find((contact) => contact.contactId === id);
      const index = body.contacts.indexOf(deleteContact);
      body.contacts.splice(index, 1);
      this.$http
        .delete(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/`, body)
        .then(() => {
          this.showOverlay = false;
          this.toastSuccess('Contacts updated successfully!')
        })
        .catch((error) => {
          this.showOverlay = false;
          this.toastError(error, 'Unable to update contacts.')
        });
    },
    async handleSubmit(apiCall) {
      const v$ = useVuelidate(this.rules, this.contact);
      const result = await v$.value.$validate();
      this.validate = v$;
      if (result) {
        apiCall();
      }
    },
    handleClose() {
      this.$emit('refresh');
      this.$emit('update', false);
    },
  },
  components: {
    AdaptifyOverlay,
    ToastSubmit,
    DialogWrapper,
    InputErrorWrapper,
    AddEditDeleteButtons,
  },
};
</script>
