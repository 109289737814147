<template>
  <nav
    class="header"
    id="styleguideheader"
    role="navigation">
    <CustomerNotifications
      :notifications="notifications"
      @refresh="getNotifications()"
      ref="modal_customernotifications"/>
    <CustomerProfile
      ref="modal"
     :currentUser="currentUser" />
    <div class="container-fluid">
      <div class="header-panels">
        <div class="header-panel hidden-md-down">
          <a
            class="header__logo"
            href="http://www.cisco.com"
            target="_blank">
            <span class="icon-cisco"></span>
          </a>
          <h1
            class="header__title"
            style="margin-left: 30%">
            <span v-if="this.organization">
              Customer: {{this.organization!==null ? this.organization.name : "N/A"}}
             </span>
          </h1>
        </div>
        <div
          class="header-panel header-panel--center base-margin-left base-margin-right hidden-lg-up">
          <a
            class="header__logo"
            href="http://www.cisco.com"
            target="_blank">
            <span class="icon-cisco"></span>
          </a>
        </div>
        <div class="header-panel header-panel--right hidden-md-down">
          <a
            href="#/home"
            class="header-item"
            title="Home"
            ><span class="icon-home"></span>
          </a>
          <a
            href="#/home"
            class="header-item"
            title="Activity"
            @click.prevent="showNotifications()"
            ><span class="icon-alert">{{ notificationsCount }}</span>
          </a>
          <a
            href="#/home"
            class="header-item"
            title="User"
            @click.prevent="showProfile()">
            {{ this.currentUser !== null ? this.currentUser.firstName + " " + this.currentUser.lastName : "N/A" }}
          </a>
          <a
            href="#"
            class="header-item"
            @click="logoutEvent()">
            Logout
          </a>
        </div>
      </div>
    </div>
  </nav>
  <br />
</template>

<script>
import CustomerNotifications from '../views/CustomerNotifications.vue';
import CustomerProfile from './databoxes/inputforms/CustomerProfile.vue';
import { getCookieValue, deleteCookie } from '@/auth/Functions';
import { CookieName, UserCookieName } from '@/auth/KCConfig';
export default {
  data() {
    return {
      notifications: [],
      notificationsCount: "",
      currentUser: null,
      organization: null,
    };
  },
  async created() {
    await this.getCurrentUser();
    await this.getOrganization(this.organizationId);
    await this.getNotifications();
    this.notificationsInverval = setInterval(async() => {
      this.getNotifications();
    }, 30000);
  },
  methods: {
    showNotifications() {
      this.$refs.modal_customernotifications.handleModal();
    },
    showProfile() {
      this.$refs.modal.handleModal();
    },
    getCurrentUser() {
      const userData = JSON.parse(getCookieValue(UserCookieName));
      const userEmail = userData.email;
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/user/me`, {
          headers: {
            'c5-userId': userEmail
          }
        })
        .then((c5User) => {
          this.currentUser = c5User.data;
          this.organizationId = c5User.data.organizationId;
          this.getOrganization(this.organizationId);
        })
        .catch(error => {
          console.error(`Unable to C5 user data`, error);
          getCookieValue(CookieName) ? deleteCookie(CookieName) : "";
          getCookieValue(UserCookieName) ? deleteCookie(UserCookieName) : "";
          this.$router.push('/');
        });
    },
    getOrganization(organizationId) {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${organizationId}`
        )
        .then((response) => (this.organization = response.data))
        .catch((e) => console.error(e));
    },
    getNotifications() {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/notification`
        )
        .then((response) => {
          if (response.status == 200) {
            this.notifications = response.data;
            this.notificationsCount = this.countUnacknowledgedNotifications();
          } else {
            this.error = `Unable to display notifications. Server responded with "${response.status}"`;
          }
        })
        .catch((e) => {
          this.notifications = [];
          console.error(e);
        });
    },
    countUnacknowledgedNotifications() {
      return this.notifications
        .filter(notification => (notification.acknowledged === false || notification.acknowledged === null)).length;
    },
    async logoutEvent() {
      localStorage.setItem('logout-event', `logout${Math.random()})`);
      await this.$oktaAuth.signOut();
    },
    unmounted() {
      clearInterval(this.notificationsInverval);
    },
  },
  name: 'CustomerHeaderNav',
  components: {
    CustomerNotifications,
    CustomerProfile,
  },
};
</script>
