<template>
  <v-card>
    <AdaptifyOverlay :showOverlay="this.showOverlay" />
    <BoxHeader
      type="Adapters"
      :action="
        type === 'admin'
          ? () => {
              adapterEdit = null;
              showModalAddEditAdapter = !showModalAddEditAdapter;
            }
          : null
      "
      :disableButton="this.environmentSelected === null"
      @update="searchValue = $event" />
      <div class="form-group base-margin-bottom">
      <span class="row">
        <span class="col-2">
          <div class="form-group__text select">
            <select v-model="environmentSelected" v-on:change="this.getAdapters()">
              <option
                v-for="orderEnvironment in envs"
                :key="orderEnvironment.id"
                :value="orderEnvironment.id" >
                {{ orderEnvironment.description }}
              </option>
            </select>
            <label for="input-type-select" class="labels">Environment</label>
          </div>
        </span>
        <span class="col-3" v-if="this.environmentSelected !== null">
          <div class="flex-center-vertical">
            <div class="flex-fluid half-margin-left text-ellipsis">
                <span class="labels">Controller status</span>
                <div class="text-ellipsis">{{ this.controllerStatus }}</div>
            </div>
         </div>
        </span> 
        <span class="col-3" v-if="this.environmentSelected !== null && this.adapters.length > 0">
          <div class="flex-center-vertical">
            <a href="javascript:void(0)" @click="downloadConfigFile('ova')">
              <div class="flex-center">
                  <span class="icon-download icon-size-24"></span>
              </div>
              <div class="flex-fluid half-margin-left text-ellipsis">
                  <div class="text-ellipsis">Download OVA File</div>
              </div>
            </a>
         </div>
        </span>
        <span class="col-3" v-if="this.environmentSelected !== null && this.adapters.length > 0">
          <a href="javascript:void(0)" @click="downloadConfigFile('config')">
            <div class="flex-center-vertical">
              <div class="flex-center">
                  <span class="icon-download icon-size-24" ></span>
              </div>
              <div class="flex-fluid half-margin-left text-ellipsis">
                  <div class="text-ellipsis">Download controller configuration</div>
              </div>
          </div>
          </a>
        </span>
      </span>
    </div>
    <EasyTable
      alternating
      :items="adapters"
      buttons-pagination
      :rows-per-page="5"
      :fixed-header="false"
      :headers="columns"
      :hide-rows-per-page="true"
      :filter-options="filterOptions"
      v-model:search-value="searchValue"
      table-class-name="adaptifytable-table">
      <template #header="header">
        <div class="adaptifytable-header">
          {{ header.text }}
        </div>
      </template>
      <template #header-startStopMsg="header">
        <div class="adaptifytable-header">
          {{ header.text }}
          <div class="icon-filter icon-size-18 filter-icon" :class="startStopMsgCriteria !== 'all' ? 'filter-applied' : '' " v-if="this.environmentSelected" @click.stop="showStatusFilter=!showStatusFilter" />
          <transition name="slide-right-left">
            <div v-if="showStatusFilter">
            <select
                class="filter-select"
                v-model="startStopMsgCriteria"
                name="status"
            >
                <option value="started">
                Started
                </option>
                <option value="stopped">
                Stopped
                </option>
                <option value="Adapter is not Configured">
                Not Configured
                </option>
                <option value="all">
                All
                </option>
            </select>
            </div>
        </transition>
        </div>
      </template>
      <template #empty-message>
        <ErrorLoader
          :error="error"
          :data="adapters"
          :type="`Adapters`"
          :loading="loading"
          v-if="loading || error" />
        <AdaptifyAlert v-else-if="adapters.length > 0" type="info" :message="`No adapters found with that filter criteria`" />
        <AdaptifyAlert v-else type="info" :message="`Select Environment`" />
      </template>
      <template #item-licenseExpirationDate="{licenseExpirationDate}">
        <div :class=processExpiringDate(licenseExpirationDate)> {{ licenseExpirationDate }} </div>
      </template>
      <template
        #item-startstop="{
          adapterStatus,
          showStartStop,
          productInstanceId,
          startStopChecked }">
        <div class="col-md-6">
          <div v-if="adapterStatus === this.status.Loading">
            <RowLoader />
          </div>
          <div v-if="showStartStop">
             <blockquote class="blockquote--center">
              <label class="switch">
                <input
                  type="checkbox"
                  v-if="startStopChecked"
                  checked
                  @click="this.startStopAdapter(productInstanceId, 'stop')"/>
                <input
                  type="checkbox"
                  v-if="!startStopChecked"
                  @click="this.startStopAdapter(productInstanceId, 'start')"/>
                  <span class="switch__input" />
              </label>
            </blockquote>
          </div>
        </div>
      </template>
      <template
        #item-startStopMsg="{
          adapterStatus,
          statusMsg}" >
        <div class="col-md-12">
          <div v-if="adapterStatus === this.status.Loading">
            <RowLoader />
          </div>
          <div v-else-if="(adapterStatus === 'stopped') || (adapterStatus === 'started')">
            <AdaptifyAlert v-if="statusMsg!== ''" type="info-solid" :message="`${statusMsg}`" :animate="true"/>
          </div>
          <div v-else>
            <AdaptifyAlert v-if="statusMsg!== ''" type="info" :message="`${statusMsg}`" :animate="true"/>
          </div>
        </div>
      </template>

      <template #item-productInstanceId="{ productInstanceId, adapterStatus }">
        <div v-if="adapterStatus !== this.status.Started">
          <CiscoButton
            color="blue"
            type="configurations"
            :action="() => openEditConfiguration(productInstanceId)"/>
        </div>
      </template>
      <template #item-reports="{ productInstanceId, adapterStatus, isHDA, adapterconfigured, startStopChecked }">
        <CiscoButton
          v-if="(adapterStatus === this.status.Started || adapterconfigured) && isHDA"
          type="report"
          color="blue"
          :action="() => openReports(productInstanceId, startStopChecked)" />
        <AdaptifyDialogAlert v-model="showAlertReports" :height="'20'" @update="showAlertReports = $event" :showDialog="showAlertReports" :messages="errorMessages" />
      </template>
      <template #item-alerts="{ productInstanceId, internalName }">
        <CiscoButton
          type="team-collapsed-view"
          color="blue"
          :action="() => openMetrics(productInstanceId, internalName)" />
          Days <select v-model="adapterSelect[productInstanceId]">
            <option v-for="opt in 7" :key="opt">{{ opt }}</option>
          </select>
      </template>
    </EasyTable>
  </v-card>
  <br />
  <AddEditAdapter
    :adapters="adapters"
    @refresh="getAdapters()"
    :adapterEdit="adapterEdit"
    v-if="showModalAddEditAdapter"
    :organizationId="organizationId"
    v-model="showModalAddEditAdapter"
    @update="showModalAddEditAdapter = $event"
    :showModalAddEditAdapter="showModalAddEditAdapter"
  />
  <EditConfiguration
    v-model="showConfigurations"
    :productInstance="productInstance"
    @update="showConfigurations = $event"
    :showConfigurations="showConfigurations"
    :organizationId="organizationId"
  />
  <Reports
    v-model="showReports"
    :productInstance="productInstance"
    :manualReportsIndex="manualReportsIndex"
    :manualReportType="manualReportType"
    @update="showReports = $event"
    :showReports="showReports"
  />
  <Metrics
    v-model="showMetrics"
    :metrics="metrics"
    :adapterName="adapterName"
    :showMetrics="showMetrics"
    @update="showMetrics = $event"
  />
  <template>
    <DialogWrapper header="Error" width="40%" height="40%" :show="showAlert">
      <v-card>
        <AdaptifyAlert
          type="warning"
          :message="`Unable to start/stop adapter`"
        />
      </v-card>
    </DialogWrapper>
  </template>
</template>

<script>
import BoxHeader from './BoxHeader.vue';
import EasyTable from 'vue3-easy-data-table';
import CiscoButton from '../commons/CiscoButton.vue';
import AdaptifyAlert from '../commons/AdaptifyAlert.vue';
import AdaptifyDialogAlert from '../commons/AdaptifyDialogAlert.vue';
import AddEditAdapter from './inputforms/AddEditAdapter.vue';
import ErrorLoader from '@/components/commons/ErrorLoader.vue';
import AdaptifyOverlay from '@/components/commons/AdaptifyOverlay.vue';
import RowLoader from '@/components/commons/RowLoader.vue';
import EditConfiguration from './inputforms/EditConfiguration.vue';
import DialogWrapper from './inputforms/DialogWrapper.vue';
import Reports from './inputforms/Reports.vue';
import Metrics from './inputforms/Metrics.vue';
import { ref, computed } from 'vue';

export default {
  props: {
    type: String,
    organizationId: String,
  },
  data() {
    const status = Object.freeze({
      Loading: "",
      AdapterNotConfigured: "Adapter is not Configured",
      ControllerNotConfigured: "Controller is not Configured",
      OK: "Ok",
      Error: "error",
      Timeout: "Timeout",
      Started: "started",
      Stopped: "stopped"
    });
    const commands = Object.freeze({
      start: {
        commandTypeId: "7d035996-6979-4125-992d-c70802d871a9",
        commandTypeName: "start",
        commandTypeDescription: "Starting Service",
      },
      stop: {
        commandTypeId: "7d035996-6979-4125-992d-c70802d871a9",
        commandTypeName: "stop",
        commandTypeDescription: "Stopping Service",
      },
    });
    const startStopMsgCriteria = ref('all');
    const filterOptions = computed(() => {
      const filterOptionsArray = [];
      if (startStopMsgCriteria.value !== 'all') {
        filterOptionsArray.push({
          field: 'adapterStatus',
          comparison: '=',
          criteria: startStopMsgCriteria.value,
        });
      }
      return filterOptionsArray;
    });
    return {
      adapterName: null,
      loadingModal: false,
      showOverlay: false,
      polling: null,
      status,
      commands,
      error: null,
      adapters: [],
      envs: [],
      adapterSelect: [],
      metrics: [],
      adaptersInterval: null,
      adapter: null,
      environmentSelected: null,
      controllerSelected: null,
      loading: false,
      showAlert: false,
      showAlertReports: false,
      errorMessages: [],
      searchValue: "",
      adapterEdit: null,
      productInstance: null,
      showConfigurations: false,
      showModalAddEditAdapter: false,
      showReports: false,
      showMetrics: false,
      manualReportsIndex: -1,
      manualReportType: null,
      controllerStatus: "N/A",
      showStatusFilter: false,
      filterOptions,
      startStopMsgCriteria, 
      columns: [
        {
          text: "Name",
          value: "prefixName",
        },
        {
          text: "Internal Name",
          value: "internalName",
        },
        {
          text: "Expires On",
          value: "licenseExpirationDate",
        },
        {
          text: "Start/Stop",
          value: "startstop",
        },
        {
          text: "Status",
          value: "startStopMsg",
          width: 250,
        },
        {
          text: "Configuration",
          value: "productInstanceId",
        },
        {
          text: "Manual Reports",
          value: "reports",
        },
        {
          text: "Metrics",
          value: "alerts",
        }
      ],
    };
  },
  async created() {
    this.getEnvs();
  },
  methods: {
    downloadConfigFile(file) {
      this.showOverlay = true;
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/controller/${this.controllerSelected}/${file}`, { validateStatus: function(status) {
            return status < 500;
          } } 
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/yaml' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download =  `controller_${this.controllerSelected}_${this.environmentSelected}_docker-compose.yaml`
          link.click()
          URL.revokeObjectURL(link.href)
          this.showOverlay = false;
        })
        .catch((error) => {
          console.error(error);
          this.showOverlay = false;
          return null;
        });
    },
    getEnvs() {
      this.showOverlay = true;
      this.adapters = [];
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/orderenvironment`)
        .then((response) =>  {
          this.envs = response.data
          this.showOverlay = false;
        })
        .catch((error) => {
          this.toastError(error, 'Unable to fetch envrionments')
          this.showOverlay = false;
        });
    },
    getAdapters() {
      this.showOverlay = true;
      this.controllerStatus = "N/A";
      if (this.adaptersInterval !== null) {
        clearInterval(this.adaptersInterval);
      }
      this.adapters = [];
      if (this.environmentSelected !== null) {
        this.loading = true;
        this.$http
          .get(
            `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/environment/${this.environmentSelected}/order`,
            {
              validateStatus: status => status < 500
            }
          )
          .then((response) => {
            this.showOverlay = false;
            if (response.status === 200) {
              this.adapters = response.data.orderItems.flatMap(orderItem => orderItem.productInstances);
              this.adapters.forEach((adapterRow) => {
                this.adapterSelect[adapterRow.productInstanceId] = "1";
                adapterRow.controllerId = response.data.controllers[0].controllerId;
                this.controllerSelected = response.data.controllers[0].controllerId;
                adapterRow.startStopStatus = false;
                adapterRow.pollAdapter = true;
                adapterRow.isHDA = adapterRow.product.productType.includes("HDA") ? true : false;
                adapterRow.adapterconfigured = adapterRow.configured;
                this.setRowValues(adapterRow, this.status.Loading, false, false, null);
                this.getAdapterStatus(adapterRow);
                this.adaptersInterval = setInterval(() => {
                  if (adapterRow.pollAdapter) {
                    if (this.getAdapterStatus(adapterRow) === null) {
                      clearInterval(this.adaptersInterval)
                    }
                  } 
                }, 15000);
              });
            } else {
              this.adapters = [];  
            } 
          })
          .catch((error) => {
            this.showOverlay = false;
            this.error = `No adapters found`;
            this.controllerSelected = null;
            console.error(error);
          });
        this.loading = false;
      }
    },

    getAdapterStatus(adapterRow) {
      return this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/productinstance/${adapterRow.productInstanceId}/status`, { validateStatus: function(status) {
            return status < 500;
          } } 
        )
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.configured) {
              this.setRowValues(adapterRow, this.status.AdapterNotConfigured, this.status.AdapterNotConfigured, null, null);
            } else if (response.data.running) {
              this.setRowValues(adapterRow, this.status.Started, this.status.Started, true, true, false);
            } else if (!response.data.controllerStatusDto.configured) {
              this.setRowValues(adapterRow, this.status.ControllerNotConfigured, this.status.ControllerNotConfigured, null), null;
            } else if (response.data.configured && response.data.controllerStatusDto.configured) {
              this.setRowValues(adapterRow, this.status.Stopped, this.status.Stopped, false, true, null);
            }
            if (response.data.controllerStatusDto !== null) {
              this.controllerStatus = response.data.controllerStatusDto.running ? "Running" : "Stopped"
            }
          }
          return response.data;
        })
        .catch((error) => {
          console.error(error);
          this.setRowValues(adapterRow, this.status.Error, this.status.Error, null, false, null);
          return null;
        });
    },

    processExpiringDate(licenseExpirationDate) {
      const expiryDate = new Date(licenseExpirationDate);
      const currentDate = new Date();
      return expiryDate < currentDate ? 'text-danger' : '';
    },
  
    openEditConfiguration(instanceId) {
      this.loading = true;
      this.showOverlay = true;
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/productinstance/${instanceId}`
        )
        .then((response) => {
          this.productInstance = response.data;
          if (this.productInstance.licenseIssueDate !== null) {
            this.productInstance.licenseIssueDate = this.productInstance.licenseIssueDate.substring(0,10);
          }
          if (this.productInstance.licenseExpirationDate !== null) {
            this.productInstance.licenseExpirationDate = this.productInstance.licenseExpirationDate.substring(0,10);
          }
          this.showConfigurations = true;
          this.showOverlay = false;
        })
        .catch((error) => {
          this.showOverlay = false;
          console.error(error)
        });
      this.loading = false;
      
    },

    openMetrics(instanceId, adapterNm) {
      this.loading = true;
      this.showOverlay = true;
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/productInstance/${instanceId}/productInstanceMetrics?lastDays=${this.adapterSelect[instanceId]}`
        )
        .then((response) => {
          this.adapterName = adapterNm;
          this.metrics = response.data;
          this.showMetrics = true;
          this.showOverlay = false;
        })
        .catch((error) => {
          console.error(error)
          this.showOverlay = false;
        });
      this.loading = false;
    },

    getCommandType(command) {
      return this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/commandType/name/${command}`
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    },
    
    getCommandStatus(uri) {
      return this.$http.get(uri)
        .then(response => response.data)
        .catch((error) => {
          console.error(error);
          return null;
        });
    },
    executeCommandType(bodyResponse, controllerId, productInstanceId, organizationId) {
      const body = {
        commandType: bodyResponse,
        organizationId: organizationId,
        controllerId: controllerId,
        productInstanceId: productInstanceId,
        controllerRegistrationKey: "",
        controllerConfigUri: "",
      };
      return this.$http
        .post(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/command`,
          body
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    },

    getSecondsBetweenStartDateAndCurrentDate(startDate) {
      return ((new Date().getTime() - startDate.getTime()) / 1000);
    },

    startStopAdapter(productInstanceId, command) {
      this.adapters
        .filter((adaptTmp) => adaptTmp.productInstanceId === productInstanceId)
        .forEach(async(adaptTmp) => {
          this.setRowValues(adaptTmp, this.status.Loading, this.status.Loading, null, false, true);
          const commandType = await this.getCommandType(command);
          if (commandType === null) {
            adaptTmp.startStopLoading = false;
            adaptTmp.pollAdapter = true;       
            this.showAlert = true;
            return;
          }
          adaptTmp.pollAdapter = false;
          const execCommandType = await this.executeCommandType(
            commandType,
            adaptTmp.controllerId,
            adaptTmp.productInstanceId,
            adaptTmp.organizationId
          );
          this.setRowValues(adaptTmp, null, execCommandType.commandDto.commandType.commandTypeDescription, null, null);
          adaptTmp.startStopMsg = execCommandType.commandDto.commandType.commandTypeDescription;
          const startDate = new Date();
          const interval = setInterval(async() => {
            if (this.getSecondsBetweenStartDateAndCurrentDate(startDate) >= 90) {
              if (!adaptTmp.pollAdapter) {
                adaptTmp.pollAdapter = true;
                adaptTmp.startStopLoading = false;
                adaptTmp.startStopMsg = "Failed to complete the start/stop operation. Timeout";
                this.setRowValues(adaptTmp, this.status.Error, 'Failed to complete the start/stop operation. Timeout', null, false, false);
                clearInterval(interval);
              }
            } else {
              const commandStatus =  await this.getCommandStatus(`${this.config.VUE_APP_ENV_URL}${execCommandType.commandStatusUri}`);
              if (commandStatus === null || commandStatus === undefined) {
                this.setRowValues(adaptTmp, this.status.Error, 'Failed to complete the start/stop operation.', null, null, null); 
                clearInterval(interval);
              }
              adaptTmp.startStopMsg = commandStatus.commandStatusCodeDto.description;
              switch (commandStatus.commandStatusCodeDto.commandStatusCode) {
                case this.status.Started:
                  this.setRowValues(adaptTmp, null, this.status.Started, true, null, null);
                  adaptTmp.startStopMsg = execCommandType.commandDto.commandType.commandTypeDescription;
                  adaptTmp.startStopLoading = false;
                  adaptTmp.pollAdapter = true;       
                  clearInterval(interval);
                  break;
                case this.status.Stopped:
                  this.setRowValues(adaptTmp, null, this.status.Stopped, false, null, null);
                  adaptTmp.startStopLoading = false;
                  adaptTmp.pollAdapter = true;            
                  clearInterval(interval);
                  break;
                case this.status.Error:
                  this.setRowValues(adaptTmp, null, 'Failed to complete the start/stop operation', false, null, null);
                  adaptTmp.startStopLoading = false;
                  adaptTmp.pollAdapter = true;            
                  clearInterval(interval);
                  break;
                default:
                  adaptTmp.pollAdapter = false;            
                  adaptTmp.startStopMsg = this.status.Loading;
                  this.setRowValues(adaptTmp, this.status.Loading, this.status.Loading, false, false, null); 
                  break
              }
            }
          }, 15000);
        })
    },

    setRowValues(adapterRow, status, message, startStopChecked, showStartStop, startStopLoading) {
      adapterRow.adapterStatus = status;
      adapterRow.statusMsg = message;
      if (startStopChecked !== null) {
        adapterRow.startStopChecked = startStopChecked;
      }
      if (showStartStop !== null) {
        adapterRow.showStartStop = showStartStop;
      }
      if (startStopLoading !== null) {
        adapterRow.startStopLoading = startStopLoading;
      }
    },
    openReports(instanceId, startStopChecked) {
      if (startStopChecked) {
        this.loading = true;
        this.showOverlay = true;
        this.$http
          .get(
            `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/productinstance/${instanceId}`
          )
          .then((response) => {
            this.productInstance = response.data;
            this.manualReportsIndex = this.productInstance.productInstanceConfiguration.configurations.findIndex(config => config.name === 'ManualReportSettings');
            const reportModelIndex = this.productInstance.productInstanceConfiguration.configurations.findIndex(config => config.name === 'ReportingModel');
            if (this.manualReportsIndex > -1) {
              const reportType = this.productInstance.productInstanceConfiguration.configurations[reportModelIndex].productVariantPropsGroups.ReportingModel;
              this.manualReportType = reportType[reportType.findIndex(report => report.propName === 'reportingType')].propValue;
              this.showReports = true;
            }
            this.showOverlay = false;
          })
          .catch((error) => {
            this.loading = false;
            this.showOverlay = false;
            console.error(error)
          });
      } else {
        this.showAlertReports = true
        this.errorMessages = ['Please start the adapter before generating reports']
      }
    },
    unmounted() {
      clearInterval(this.adaptersInterval);
    },
    filteredAdapters() {
      return this.adapters.filter((adapter) => {
        return (
          (this.filterOptions.startStopMsg ? adapter.startStopMsg.includes(this.filterOptions.startStopMsg) : true) 
        );
      });
    },
  },
  components: {
    BoxHeader,
    EasyTable,
    ErrorLoader,
    CiscoButton,
    AdaptifyAlert,
    AdaptifyDialogAlert,
    AdaptifyOverlay,
    AddEditAdapter,
    EditConfiguration,
    RowLoader,
    DialogWrapper,
    Reports,
    Metrics
  },
};
</script>
<style>
.filter-icon{
    margin: 0.5rem;
}
.filter-applied{
    color: var(--cui-color-midnight);
}
.filter-select {
  background-color: transparent;
  color: var(--easy-table-header-font-color) !important;
  border: var(--cui-border);
  border-radius: var(--cui-border-radius);
  /* border-color: var(--cui-border); */
  transition: border-color 0.3s; /* Smooth transition for the border color */
  font-size: 12px !important;
  font-weight: 600;
  padding: 0.5rem;
}
.filter-select:hover {
  border-width: 2px;
}
.filter-select:focus {
  outline-color: var(--cui-border);
}
.slide-right-left-enter-from,
.slide-right-left-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.slide-right-left-enter-to,
.slide-right-left-leave-from {
  transform: translateX(0);
  opacity: 1;
}
.slide-right-left-enter-active,
.slide-right-left-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
</style>
