import { createApp } from 'vue';
import App from './App.vue';
import config from '../config.js';
import axios from 'axios';
import OktaAuth from '@okta/okta-auth-js';
import OktaVue from '@okta/okta-vue';
import { CookieName, UserCookieName } from './auth/KCConfig.js';
import { getCookieValue, setCookieValue, deleteCookie } from './auth/Functions';
import SplashPage from './views/Splash.vue';
import HomePage from './views/Home.vue';
import AdapterInstances from './views/Instances.vue';
import InstanceConfiguration from './views/Configuration.vue';
import CustomersPage from './views/Customers.vue';
import CustomerPage from './views/Customer.vue';
import ProductsPage from './views/Products.vue';
import ProductPage from './views/Product.vue';
import OrdersPage from './views/Orders.vue';
import AdminHome from './views/AdminHome.vue';
import CustomerHome from './views/CustomerHome.vue';
import EditConfiguration from './components/databoxes/inputforms/EditConfiguration.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

axios.interceptors.response.use((response) => {
  if (
    ![200, 201].includes(response.status)
    || (response.data.valid && response.data.valid === false)
  ) {
    return Promise.reject(
      new Error({
        message: `${response.message}`,
      })
    );
  }
  return response;
});

axios.interceptors.request.use((config) => {
  const accessToken = getCookieValue(CookieName);
  config.headers = {
    'Authorization': `Bearer ${accessToken}`
  };
  return config;
},
(error) => {
  return Promise.reject(error);
}
);
  
const oktaAuth = new OktaAuth(config.oidc);

oktaAuth.tokenManager.on('renewed', (key, newToken, oldToken) => {
  if (key === 'accessToken') {
    setCookieValue(CookieName, newToken.accessToken);
  }
});

oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
  if (key === 'accessToken') {
    console.log(`Token with key '${key}' has expired`);
    deleteCookie(UserCookieName);
    deleteCookie(CookieName);
    oktaAuth.signOut();
  }
});

oktaAuth.tokenManager.on('removed', (key) => {
  if (key === 'accessToken') {
    console.log(`Token with key '${key}' has been removed`);
    deleteCookie(UserCookieName);
    deleteCookie(CookieName);
  }
});

oktaAuth.tokenManager.on('added', (key, token) => {
  if (key === 'accessToken') {
    setCookieValue(CookieName, token.accessToken);
  }
});

oktaAuth.tokenManager.on('renewing', (key, token) => {
  if (key === 'accessToken') {
    setCookieValue(CookieName, token.accessToken);
  }
});

const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App).use(OktaVue, { oktaAuth });

window.addEventListener('storage', (event) => {
  if (event.key === 'logout-event') {
    deleteCookie(UserCookieName);
    deleteCookie(CookieName);
    oktaAuth.signOut();
  }
});

const routes = [
  {
    path: '/',
    component: SplashPage
  },
  {
    path: '/home',
    component: HomePage,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/adminhome',
    component: AdminHome,
    meta: {
      requiresAuth: true,
      roles: [config.ROLE_CISCO_ADMINISTRATOR]
    },
  },
  {
    path: '/customerhome',
    component: CustomerHome,
    meta: {
      requiresAuth: true,
      roles: [config.ROLE_ADMINISTRATOR]
    },
  },
  {
    path: '/instances',
    component: AdapterInstances,
    meta: {
      requiresAuth: true,
      roles: [config.ROLE_CISCO_ADMINISTRATOR]
    },
  },
  {
    path: '/configuration',
    component: InstanceConfiguration,
    meta: {
      requiresAuth: true,
      roles: [config.ROLE_CISCO_ADMINISTRATOR]
    },
  },
  {
    path: '/edit_configuration',
    component: EditConfiguration,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/customers',
    component: CustomersPage,
    meta: {
      requiresAuth: true,
      roles: [config.ROLE_CISCO_ADMINISTRATOR]
    },
  },
  {
    path: '/customer/:id',
    component: CustomerPage,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/products',
    component: ProductsPage,
    meta: {
      requiresAuth: true,
      roles: [config.ROLE_CISCO_ADMINISTRATOR]
    },
  },
  {
    path: '/product/:id',
    component: ProductPage,
    meta: {
      requiresAuth: true,
      roles: [config.ROLE_CISCO_ADMINISTRATOR]
    },
  },
  {
    path: '/orders',
    component: OrdersPage,
    meta: {
      requiresAuth: true,
      roles: [config.ROLE_CISCO_ADMINISTRATOR]
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

router.beforeEach(async(to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = await oktaAuth.isAuthenticated();
    if (!isAuthenticated) {
      next({ path: '/' });
    } else {
      const userData = JSON.parse(getCookieValue(UserCookieName));
      const userRoles = userData.roles;
      if (to.matched.some(record => record.meta.roles)) {
        const requiredRoles = to.meta.roles;
        const hasRole = requiredRoles.some(role => userRoles.includes(role));
        if (hasRole) {
          next();
        } else {
          next({ path: '/home' });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

app.mixin({
  methods: {
    clone(x) {
      return JSON.parse(JSON.stringify(x));
    },
    formatDate(x) {
      const d = new Date(x);
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      return `${ye}-${mo}-${da}`;
    },
    toastSuccess(message) {
      this.toast.error = false;
      this.toast.message = message ? message : '';
      this.toast.showToast = message ? true : false;
    },
    toastError(error, message) {
      console.error(error);
      this.toast.error = true;
      this.toast.showToast = true;
      this.toast.message = `${message} ${error.message}`;
    },
  },
  data() {
    return {
      config: config,
      toast: {
        error: false,
        message: null,
        showToast: false,
      },
    };
  },
});
app.config.globalProperties.$http = axios;
app.config.globalProperties.$oktaAuth = oktaAuth;
app.use(router).use(vuetify);
app.mount('#app');
