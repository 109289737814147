<template>
  <CustomerHeader/>
  <div class="content contnet --alt">
    <div className="col-15 col-lg-9 col-xl-11">
      <div className="section">
        <div className="row">
          <div className="col-xl-15">
            <div
              className="panel panel--loose panel--raised base-margin-bottom"
            >
              <h2 className="subtitle">CTI Servers</h2>
              <hr />
              <div className="responsive-table">
                <table
                  className="table table--lined table--fixed"
                  aria-label="Fixed table example"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>PeripheralID</th>
                      <th>Host A</th>
                      <th>Port A</th>
                      <th>Host B</th>
                      <th>Port B</th>
                      <th>MultiMedia</th>
                      <th>CTIProtocol</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>192.168.0.245</td>
                      <td>--</td>
                      <td>60-A4-B7-59-90-A4</td>
                      <td>Online</td>
                      <td>1</td>
                      <td>3</td>
                      <td>14</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>192.168.0.245</td>
                      <td>--</td>
                      <td>60-A4-B7-59-90-A4</td>
                      <td>Online</td>
                      <td>1</td>
                      <td>3</td>
                      <td>14</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>192.168.0.245</td>
                      <td>--</td>
                      <td>60-A4-B7-59-90-A4</td>
                      <td>Online</td>
                      <td>1</td>
                      <td>3</td>
                      <td>14</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>192.168.0.245</td>
                      <td>--</td>
                      <td>60-A4-B7-59-90-A4</td>
                      <td>Online</td>
                      <td>1</td>
                      <td>3</td>
                      <td>14</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>192.168.0.245</td>
                      <td>--</td>
                      <td>60-A4-B7-59-90-A4</td>
                      <td>Online</td>
                      <td>1</td>
                      <td>3</td>
                      <td>14</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>192.168.0.245</td>
                      <td>--</td>
                      <td>60-A4-B7-59-90-A4</td>
                      <td>Online</td>
                      <td>1</td>
                      <td>3</td>
                      <td>14</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>192.168.0.245</td>
                      <td>--</td>
                      <td>60-A4-B7-59-90-A4</td>
                      <td>Online</td>
                      <td>1</td>
                      <td>3</td>
                      <td>14</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="panel panel--loose panel--raised base-margin-bottom"
            >
              <h2 className="subtitle">Options</h2>
              <hr />
              <div className="row">
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">Meters</div>
                  <label className="checkbox">
                    <input type="checkbox" checked />
                    <span className="checkbox__input"></span>
                  </label>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">Msgs from CTIServer</div>
                  <label className="checkbox disabled">
                    <input type="checkbox" checked />
                    <span className="checkbox__input"></span>
                  </label>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">Msgs to WFM Server</div>
                  <label className="checkbox">
                    <input type="checkbox" checked className="hover" />
                    <span className="checkbox__input"></span>
                  </label>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">Agent State</div>
                  <label className="checkbox">
                    <input type="checkbox" checked className="focus" />
                    <span className="checkbox__input"></span>
                  </label>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">CTI Server Socket</div>
                  <label className="checkbox">
                    <input type="checkbox" checked className="active" />
                    <span className="checkbox__input"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">WFM Server Socket</div>
                  <label className="checkbox">
                    <input type="checkbox" />
                    <span className="checkbox__input"></span>
                  </label>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">CTI Server Heartbeats</div>
                  <label className="checkbox disabled">
                    <input type="checkbox" />
                    <span className="checkbox__input"></span>
                  </label>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">WFM server Heartbeats</div>
                  <label className="checkbox">
                    <input type="checkbox" className="hover" />
                    <span className="checkbox__input"></span>
                  </label>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">Inter Process Components</div>
                  <label className="checkbox">
                    <input type="checkbox" className="focus" />
                    <span className="checkbox__input"></span>
                  </label>
                </div>
              </div>
            </div>
            <div
              className="panel panel--loose panel--raised base-margin-bottom"
            >
              <h2 className="subtitle">Agent State Mapping</h2>
              <hr />
              <div className="row">
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Login</b>
                  </div>
                  <div>Agent_Online</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Logout</b>
                  </div>
                  <div>Agent_Offline</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Ready</b>
                  </div>
                  <div>Wait_Busy</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Available</b>
                  </div>
                  <div>Line_Open</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Talking</b>
                  </div>
                  <div>Wait_Busy</div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Reserved</b>
                  </div>
                  <div>Agent_Reserved</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Work Not Ready</b>
                  </div>
                  <div>Standby</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Work Ready</b>
                  </div>
                  <div>Proceed</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Unknown</b>
                  </div>
                  <div>State Unknown</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Busy Other</b>
                  </div>
                  <div>Standby</div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader"><b>Hold</b></div>
                  <div>Pause</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Active</b>
                  </div>
                  <div>Ready</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Paused</b>
                  </div>
                  <div>Break</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Interrupted</b>
                  </div>
                  <div>Break</div>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="subheader">
                    <b>Not Active</b>
                  </div>
                  <div>Agent_Offline</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import CustomerHeader from '@/components/CustomerHeader.vue';
import { getCookieValue, deleteCookie } from '@/auth/Functions';
import { UserCookieName, CookieName } from '@/auth/KCConfig';

export default {
  setup() {
    const router = useRouter();
    function edit() {
      router.push('/editconfiguration');
    }
    return {
      edit,
    };
  },
  data() {
    return {
      currentUser: null,
      organizationId: null,
    };
  },
  methods: {
    getCurrentUser() {
      const userData = JSON.parse(getCookieValue(UserCookieName));
      const userEmail = userData.email;
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/user/me`, {
          headers: {
            'c5-userId': userEmail
          }
        })
        .then((c5User) => {
          this.currentUser = c5User.data;
          this.organizationId = c5User.data.organizationId;
        })
        .catch(error => {
          console.error(`Unable to C5 user data`, error);
          getCookieValue(CookieName) ? deleteCookie(CookieName) : "";
          getCookieValue(UserCookieName) ? deleteCookie(UserCookieName) : "";
          this.$router.push('/');
        });
    },
  },
  name: 'InstanceConfiguration',
  components: {
    CustomerHeader,
  },
};
</script>
