<template>
<div>
    <nav
      class="header"
      id="styleguideheader"
      role="navigation">
      <Notifications ref="modal_notifications" v-if="currentUser" v-bind:userData="currentUser"/>
      <CustomerProfile
        ref="modal"
        :currentUser="currentUser" />
      <div class="container-fluid">
        <div class="header-panels">
          <div class="header-panel hidden-md-down">
            <a
              class="header__logo"
              href="http://www.cisco.com"
              target="_blank">
              <span class="icon-cisco" />
            </a>
            <h1
              class="header__title"
              style="margin-left: 50%">
              <span v-if="this.organization">Organization: {{ organization.name }}</span>
            </h1>
          </div>
          <div class="header-panel header-panel--right hidden-md-down">
            <a
              href="/adminhome"
              class="header-item"
              title="Home"
              ><span class="icon-home"></span>
            </a>
            <a
              href="#/"
              class="header-item"
              title="Activities"
              @click.prevent="showNotifications()"
              ><span class="icon-report"></span>
            </a>
            <a
              href="/customers"
              class="header-item"
              >Customers
            </a>
            <a
              href="/products"
              class="header-item"
              >Services
            </a>
            <a
              href="#/home"
              class="header-item"
              title="Home"
              @click.prevent="showProfile()">
              {{ this.currentUser !== null ? this.currentUser.firstName + " " + this.currentUser.lastName : "N/A" }}
            </a>
            <a
              class="header-item"
              @click="logoutEvent()">
              Logout
            </a>
          </div>
        </div>
      </div>
    </nav>
    <br />
  </div>
</template>

<script>
import Notifications from '../views/Notifications.vue';
import CustomerProfile from './databoxes/inputforms/CustomerProfile.vue';
import { getCookieValue, deleteCookie } from '@/auth/Functions';
import { CookieName, UserCookieName } from '@/auth/KCConfig';

export default {
  data() {
    return {
      currentUser: null,
      organization: null,
      organizationId: null
    };
  },
  async created() {
    await this.getCurrentUser();
  },
  methods: {
    showNotifications() {
      this.$refs.modal_notifications.handleModal();
    },
    showProfile() {
      this.$refs.modal.handleModal();
    },
    getCurrentUser() {
      const userData = JSON.parse(getCookieValue(UserCookieName));
      const userEmail = userData.email;
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/user/me`, {
          headers: {
            'c5-userId': userEmail
          }
        })
        .then((c5User) => {
          this.currentUser = c5User.data;
          this.organizationId = c5User.data.organizationId;
          this.getOrganization(this.organizationId);
        })
        .catch(error => {
          console.error(`Unable to C5 user data`, error);
          getCookieValue(CookieName) ? deleteCookie(CookieName) : "";
          getCookieValue(UserCookieName) ? deleteCookie(UserCookieName) : "";
          this.$router.push('/');
        });
    },
    getOrganization(organizationId) {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${organizationId}`
        )
        .then((response) => (this.organization = response.data))
        .catch((e) => console.error(e));
    },
    async logoutEvent() {
      localStorage.setItem('logout-event', `logout${Math.random()})`);
      await this.$oktaAuth.signOut();
    }
  },
  components: {
    Notifications,
    CustomerProfile,
  }
};
</script>
