<template>
  <div
    class="modal"
    id="modal-default"
    v-if="open">
    <div
      class="modal__dialog"
      style="position: relative; left: 40%; width: 20%; height: 40%">
      <div class="modal__content">
        <a
          class="text-white modal__close"
          v-on:click="handleModal"
          ><span class="icon-close"
        /></a>
        <div
          class="modal__header"
          style="background-color: #64bbe3">
          <br />
          <div
            class="text-center text-size-18"
            style="color: white">
            <span>User Profile</span>
          </div>
          <hr />
        </div>
        <div class="modal__body">
          <div class="form-group base-margin-bottom" v-if="isCiscoEmail">
            <a :href="config.VUE_WORKDAY_PROFILE_URL">
              <CiscoButton
                type="user"
                color="blue"
                label="Manage profile (Workday)"
                />
            </a>
          </div>
          <div class="form-group base-margin-bottom" v-else>
            <a :href="config.VUE_CCO_PROFILE_URL">
              <CiscoButton
                type="user"
                color="blue"
                label="Manage CCO profile"
                />
            </a>
          </div>
          <div class="form-group base-margin-bottom" v-if="isCiscoAdministrator">
            <CiscoButton
                  type="pencil"
                  color="blue"
                  label="Edit roles"
                  :action="
                    () => {
                      handleModal();
                      showModalAddEditUser = true;
                      showChangePsw = false;
                    }
                  " />
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddEditUser
    :userEdit="currentUser"
    v-if="showModalAddEditUser"
    v-model="showModalAddEditUser"
    @refresh="handleModal()"
    :organizationId="currentUser.organizationId"
    :showModalAddEditUser="showModalAddEditUser"
    @update="showModalAddEditUser = $event" />
</template>

<script>
import AddEditUser from './AddEditUser.vue';
import CiscoButton from '@/components/commons/CiscoButton.vue';

export default {
  props: { currentUser: Object },
  data() {
    return {
      open: false,
      userEdit: null,
      showChangePsw: false,
      showModalAddEditUser: false,
    };
  },
  computed: {
    isCiscoEmail() {
      const domain = this.currentUser.email.split('@')[1];
      console.log(this.currentUser)
      return domain === 'cisco.com';
    },
    isCiscoAdministrator() {
      return this.currentUser.roles.includes(this.config.ROLE_CISCO_ADMINISTRATOR);
    }
  },
  methods: {
    handleModal() {
      this.open = !this.open;
    },
    
  },
  components: {
    AddEditUser,
    CiscoButton,
  },
};
</script>
