<template>
  <v-dialog
    width="auto"
    v-if="showConfigurations"
    persistent
    scrollable
  >
    <v-card width="90%" class="form-group base-margin-bottom">
      <v-toolbar>
        <v-toolbar-title class="text-size-24">Configurations</v-toolbar-title>
        <v-toolbar-items>
          <v-btn @click="this.handleClose()">
            <div :class="`icon-close icon-size-24`" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs
        v-model="tab"
        show-arrows="always"
        next-icon="icon-chevron-right"
        prev-icon="icon-chevron-left">
        <v-tab
            class="v-tab-edit"
            v-for="(
              productVariant, index
            ) in productInstanceTmp.productInstanceConfiguration.configurations.filter(variant => variant.name !== 'ManualReportSettings').sort(
              this.dynamicSort('displayOrder')
            )"
            :key="index">
            {{ productVariant.name }}
        </v-tab>
        <v-tab
            class="v-tab-edit"
            :key="0">
            License
        </v-tab>
      </v-tabs>

      <v-card-text>
        <AdaptifyDialogAlert v-model="showErrors" :height="'50'" @update="showErrors = $event" :showDialog="showErrors" :messages="errorMessages" />
        <AdaptifyDialogAlert v-model="showBusinessErrors" :height="'50'" @update="showBusinessErrors = $event" :showDialog="showBusinessErrors" :messages="businessErrorMessages" />
        <AdaptifyOverlay :showOverlay="this.showOverlay" />
        <div class="cui">
          <ToastSubmit :toastData="toast" v-if="toast.showToast"/>
        </div>
        <br/>
        <br/>
        <v-window v-model="tab" continuous>
          <v-window-item
            v-for="(
              productVariant, index
            ) in productInstanceTmp.productInstanceConfiguration.configurations.filter(variant => variant.name !== 'ManualReportSettings').sort(
              this.dynamicSort('displayOrder')
            )"
            :key="index"
            height="60%"
          >
            <v-card width="auto" class="cui" >
              <template v-slot:title>
                <h6 class="text-primary">Description: {{ productVariant.description }}</h6>
                <h7 class="text-secondary" v-if="productVariant.description === 'CustomStoredProcedures'" >Select a file for the stored procedure and click on the cloud button to save changes by uploading it. </h7>
              </template>
              
              <template v-slot:text>                
                  <div
                    class="section flex"
                    v-for="
                      (prodVariantGroup, propertyName, index)
                      in productVariant.productVariantPropsGroups"
                      :key="index"
                  > 
                  <h6>
                    <div v-if="Object.keys(productVariant.productVariantPropsGroups).length > 1">
                      <span >
                        Select Group: 
                      </span>
                      <input
                        type="checkbox"
                        v-model="this.activeGroups[propertyName.split(' ').join('_')].active"
                      />
                    </div>
                  </h6>
                  <br><br>
                  <div v-if="this.activeGroups[propertyName.split(' ').join('_')].active" class="responsive-table">
                    <div class="flex flex-left flex-wrap">
                      <div  
                        v-for="(
                          productVariantProp, index2
                        ) in prodVariantGroup.sort(
                          this.dynamicSort('displayOrder')
                        )"
                        :key="index2"
                      >
                      <div class="form-group form-group--inline">
                        <InputErrorWrapper
                          class="form-group__text"
                          :property="(propertyName.split(' ').join('_')+'__'+productVariantProp.propName)"
                          :validate="validate"
                        >
                          <label v-if="productVariantProp.propType === 'Checkbox'" class="checkbox">
                            <input
                              type="checkbox"
                              v-model="
                              productVariantProp.propValue
                              "
                              :disabled="dependsOnOtherProp( productVariantProp.propTypeValidationRule === 'dependsOn' , propertyName, productVariantProp )"
                            />         
                            <span
                              class="checkbox__input"
                              :class="{ 'checkbox__input__disabled': dependsOnOtherProp(productVariantProp.propTypeValidationRule === 'dependsOn', propertyName, productVariantProp) }"
                            ></span>
                            <span class="checkbox__label"
                              :class="{ 'checkbox__label__disabled': dependsOnOtherProp(productVariantProp.propTypeValidationRule === 'dependsOn', propertyName, productVariantProp) }"
                            >{{productVariantProp.propDisplayName}}</span>
                          </label>
                          <div class="form-group__text select" v-if="productVariantProp.propType ==='Select'">
                            <select v-model="productVariantProp.propValue">
                              <option
                                v-for="sel,index in productVariantProp.propTypeAttribute.split(',')"
                                :key="index"
                                :value="sel">   
                                {{ sel }}
                              </option>
                            </select>
                            <label for="input-type-select">{{productVariantProp.propDisplayName}}</label>
                          </div>
                          <div>
                            <label v-if="productVariantProp.propType ==='IntervalTime'">
                              <span class="checkbox__label">{{productVariantProp.propDisplayName}}</span>
                            </label>
                            <vue-timepicker v-if="productVariantProp.propType ==='IntervalTime'" 
                                :minute-interval="this.adapterConfig.ReportingModel__ucceInterval"
                                :modelValue="productVariantProp.propValue"
                                v-model="productVariantProp.propValue"
                                @click="this.defineRules()"
                            /> &nbsp;
                          </div>
                          <input v-if="productVariantProp.propType !=='IntervalTime' && productVariantProp.propType !== 'Checkbox' && 
                              productVariantProp.propType !== 'File' && productVariantProp.propType !== 'Select'" 
                              :type="this.inputType[productVariantProp.propType]"
                              :disabled="productVariantProp.propType !== 'ReadOnlyText' ? disabled : ''"
                              :size="parseInt(productVariantProp.propTypeSize)+3"
                              :maxlength="productVariantProp.propTypeSize"
                              v-model="productVariantProp.propValue"
                          />
                          <label v-if="productVariantProp.propType === 'File'" class="text-center type_file_card">
                            <div class="row">
                                <div class="col-12">
                                    <h6 class="checkbox__label">{{productVariantProp.propDisplayName}}</h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <input 
                                        class="type_file_card_input"
                                        type=file
                                        :disabled="productVariantProp.propType !== 'ReadOnlyText' ? disabled : ''"
                                        :size="parseInt(productVariantProp.propTypeSize)+3"
                                        :maxlength="productVariantProp.propTypeSize"
                                        @change="handleFileChange($event, productVariantProp.propId)"
                                    />       
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 align-self-center">
                                    <CiscoButton
                                        :color="this.files[productVariantProp.propId] == undefined ? 'gray' : 'blue'"
                                        type="cloud-upload"
                                        :label="`Upload`"
                                        :action="() => uploadFile(productInstanceTmp.productInstanceId,productVariantProp.propId)"
                                        :isDisabled="this.files[productVariantProp.propId] == undefined"/>
                                </div>
                                <div class="col-6 align-self-center" v-if="productVariantProp.propValue !== ''">
                                    <a @click="downloadFile(productVariantProp.propValue, productVariantProp.propDisplayName)" href="javascript:void(0)" >
                                        <div :class="`icon-download icon-size-24`" /> Download
                                    </a>
                                </div>
                            </div>
                          </label>
                          <label v-if="productVariantProp.propType !== 'Checkbox' && productVariantProp.propType !== 'IntervalTime' && 
                            productVariantProp.propType !== 'File' && productVariantProp.propType !== 'Select'" > 
                            {{productVariantProp.propDisplayName}}    
                          </label>
                        </InputErrorWrapper>
                      </div>
                    <br />
                    <br />
                  </div>
                </div>
                </div>
                </div>
                <div v-if="showUpdateButton && productVariant.description !== 'CustomStoredProcedures'">
                  <AddEditDeleteButtons
                    v-if="allowUpdateButton"
                    type="update"
                    :add="() => handleSubmit(updateVariants)"
                    :update="() => {}" />
                  <AddEditDeleteButtons
                    v-if="!allowUpdateButton"
                    type="Error"
                    :error="() => {}"/>
                  </div>
                <div>
                  <span v-if="!showUpdateButton">
                    <div
                      class="loading-dots"
                      aria-label="Loading, please wait...">
                      <span
                        v-for="index in 5"
                        :key="index" />
                    </div>
                  </span>
                </div>
              </template>
            </v-card>
          </v-window-item>
          <v-window-item class="text-size-24" :key="0">
            <v-card width="auto" class="cui">
              <template v-slot:title>
                <h3>License:</h3>
              </template>
              <template v-slot:text>
                <div
                  class="form-group base-margin-bottom"
                  style="overflow-y: scroll; height: 90%">
                  <div class="row">
                    <div class="col-md-3">
                      <InputErrorWrapper
                        property="licenseKey"
                        :validate="validate">
                        <div class="form-group__text">
                          <textarea
                            rows="12"
                            v-model="productInstanceTmp.licenseKey"
                            @input="validateLicense()"/>
                          <label>License Key</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                    <div class="col-md-3">
                      <InputErrorWrapper
                        property="licenseIssueDate"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="date"
                            id="productIssueDate"
                            v-model="productInstanceTmp.licenseIssueDate"
                          />
                          <label for="input-type-date">License Issue Date</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                    <div class="col-md-3">
                      <InputErrorWrapper
                        property="licenseExpirationDate"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            disabled
                            type="date"
                            v-model="productInstanceTmp.licenseExpirationDate"
                          />
                          <label for="input-type-date">License Expiration Date</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                    <div class="col-md-3">
                      <div v-if="showUpdateButton">
                        <AddEditDeleteButtons
                          type="update"
                          :add="() => handleSubmit(updateVariants)"
                          :update="() => {}"/>
                      </div>
                      <div v-if="!showUpdateButton">
                        <div
                        class="loading-dots"
                        aria-label="Loading, please wait...">
                        <span
                          v-for="index in 5"
                          :key="index" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </v-card>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
import { toRef } from 'vue';
import AddEditDeleteButtons from './AddEditDeleteButtons.vue';
import ToastSubmit from './ToastSubmit.vue';
import useVuelidate from '@vuelidate/core';
import { required, numeric, alphaNum, integer, email, alpha, ipAddress, url, between, helpers } from '@vuelidate/validators';
import InputErrorWrapper from './InputErrorWrapper.vue';
import AdaptifyDialogAlert from '@/components/commons/AdaptifyDialogAlert.vue';
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
import CiscoButton from '@/components/commons/CiscoButton.vue';
import AdaptifyOverlay from '@/components/commons/AdaptifyOverlay.vue';

export default {
  props: {
    productInstance: Object,
    showConfigurations: Boolean,
    organizationId: String,
  },
  setup(props) {
    const options = [];
    const productInstanceTmp = toRef(props, 'productInstance');
    const productIdTmp = null;
    const productTypeNameTmp = null;
    return {
      options,
      productInstanceTmp,
      productIdTmp,
      productTypeNameTmp
    };
  },
  name: "InstanceConfiguration",
  methods: {
    updateVariants() {
      const body = this.productInstanceTmp;
      body.configured = true;
      body.licenseExpirationDate = body.licenseIssueDate;
      ['quantity', 'productId', 'instanceNamePrefix', 'product'].forEach(
        (field) => delete body[field]
      );
      ['licenseIssueDate', 'licenseExpirationDate'].forEach(
        (field) => (body[field] = body[field].concat('T00:00:00Z'))
      );
      this.$http
        .put(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/productinstance`,
          body
        )
        .then(response => {
          this.toastSuccess('Config updated successfully!');
          if (response.data.licenseIssueDate !== null) {
            this.productInstanceTmp.licenseIssueDate = this.formatDate(response.data.licenseIssueDate);
          }
          if (response.data.licenseExpirationDate !== null) {
            this.productInstanceTmp.licenseExpirationDate = this.formatDate(response.data.licenseExpirationDate);
          }
          this.showUpdateButton = true;
        })
        .catch((error) => {
          this.showUpdateButton = true;
          this.toastError(error, 'Unable to add adapter.')
        });
    },
    formatDate(date) {
      return date.substring(0,10);
    },
    validateLicense() {
      if (this.productInstanceTmp !== undefined) {
        this.showOverlay = true;
        const body = {
          license: this.productInstanceTmp.licenseKey,
          productId: this.productIdTmp,
          productTypeName: this.productTypeNameTmp,
        };
        this.$http
          .post(
            `${this.config.VUE_APP_ENV_URL}/c5serverlicense/v1/organization/${this.productInstanceTmp.organizationId}/license/validate`,
            body
          )
          .then((response) => {
            if (response.data.valid) {
              this.productInstanceTmp.licenseExpirationDate = this.formatDate(
                response.data.expiration
              );
              this.toast.error = false;
              this.toast.message = "Valid license";
              this.showOverlay = false;
              this.allowUpdateButton = true;
            } else {
              this.toast.error = true;
              this.toast.showToast = true;
              this.productInstanceTmp.licenseExpirationDate = "";
              this.toast.message = response.data.message;
              this.showOverlay = false;
              this.allowUpdateButton = false;
            }
          })
          .catch((error) => {
            this.toastError(error, "Unable to validate license.");
            this.showOverlay = false;
          });
      }
    },
    handleClose() {
      this.toastSuccess();
      this.$emit('refresh');
      this.$emit('update', false);
    },
    validateBusinessRules() {
      this.businessErrorMessages = [];
      if (this.adapterConfig['IntervalReportSettings__disableTcsIntervalReport'] !== null && this.adapterConfig['IntervalReportSettings__disableSgdataIntervalReport'] !== undefined) {
        if (this.adapterConfig['IntervalReportSettings__disableTcsIntervalReport'] === true && this.adapterConfig['IntervalReportSettings__generateEmptyTcsdataReport'] === true) {
          this.businessErrorMessages[0] = 'Generate Empty TCS Interval Report cannot be checked if Disable TCS Interval Report is checked'
          return false;
        }
        if (this.adapterConfig['IntervalReportSettings__disableSgdataIntervalReport'] === true && this.adapterConfig['IntervalReportSettings__generateEmptySgdataReport'] === true) {
          this.businessErrorMessages[0] = 'Generate Empty SGData Interval Report cannot be checked if Disable SGData Interval Report is checked'
          return false;
        }
      }
      return true;
    },
    async handleSubmit(apiCall) {
      this.defineRules();
      this.toastSuccess();
      const validator = useVuelidate(this.rules, this.adapterConfig);
      const result = await validator.value.$validate();
      this.validate = validator;
      if (result) {
        if (this.validateBusinessRules()) {
          this.showBusinessErrors = false;
          this.toastSuccess();
          this.showUpdateButton = false;
          apiCall();
        } else {
          this.showBusinessErrors = true;
        }
      } else {
        this.showErrors = true;
        this.errorMessages = [];
        this.validate.$errors.forEach((error,index) => {
          this.errorMessages[index] = error.$property.concat("->").concat(error.$message);
        })
      }
    },
    defineRules() {
      this.adapterConfig = [];
      this.rules = [];
      const configs = this.productInstanceTmp.productInstanceConfiguration.configurations;
      const productVariantPropGroups = configs.flatMap(element => Object.entries(element.productVariantPropsGroups))
      productVariantPropGroups.forEach(([variant, propGroup], index) => {
        propGroup.forEach(prop => { 
          if (variant !== 'ManualReportSettings') {
            const keyStem = variant.split(" ").join("_");
            const key = keyStem.concat("__").concat(prop.propName);
            this.adapterConfig[key] = prop.propValue;
            if (prop.propTypeValidationRule !== "") {
              this.rules[key] = this.activeGroups[keyStem].active
                ? this.createRules(prop, key)
                : {};
            } else {
              this.rules[key] = {};
            }
          }
        })
      })
      this.adapterConfig["licenseKey"] = this.productInstanceTmp.licenseKey;
      this.adapterConfig["licenseExpirationDate"] = this.productInstanceTmp.licenseExpirationDate;
      this.adapterConfig["licenseIssueDate"] = this.productInstanceTmp.licenseIssueDate;
      this.rules["licenseKey"] = { required };
      this.rules["licenseExpirationDate"] = { required };
      this.rules["licenseIssueDate"] = { required };
      this.validate = useVuelidate(this.rules, this.adapterConfig);
    },
    createRules(prop, key) {
      const rules = [];
      if (prop.propType.includes("Range-")) {
        const minValue = parseInt(prop.propType.substring(prop.propType.indexOf("-") + 1,prop.propType.indexOf("~")));
        const maxValue = parseInt(prop.propType.substring(prop.propType.indexOf("~") + 1,prop.propType.length));
        rules.push({
          minValue: helpers.withMessage('Min value is: '.concat(minValue), val => {
            return (parseInt(this.adapterConfig[key]) > minValue)
          }),
          maxValue: helpers.withMessage('Max value is: '.concat(maxValue), val => {
            return (parseInt(this.adapterConfig[key]) < maxValue)
          })
        })
      }  
      if (prop.propType.includes("Range_Before_Ucce")) {
        rules.push({
          minValue: helpers.withMessage('Min value is: '.concat(0), val => {
            return (parseInt(this.adapterConfig[key]) >= 0)
          }),
          maxValue: helpers.withMessage('Exceeded max value', val => {
            return (parseInt(this.adapterConfig[key]) < parseInt(this.adapterConfig['ReportingModel__ucceInterval']) - 1)
          })
        })
      
      }

      if (prop.propRequired) {
        rules.push(required)
      }
      rules.push(this.rulesType[prop.propTypeValidationRule])
      return rules;
    }, 
    propsAreEmpty(props) {
      return props.some(prop => prop.propValue === '')
    },
    handleFileChange(event, propId) {
      this.files[propId] = event.target.files[0]
    },

    uploadFile(productInstanceId, propId) {
      this.showOverlay = true;
      if (this.files[propId] !== undefined) {
        const body = new FormData()
        body.append('file',  this.files[propId])
        this.$http
          .post(
            `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.productInstanceTmp.organizationId}/productinstance/${productInstanceId}/productvariantprop/${propId}/upload`,
            body
          )
          .then((response) => {
            this.toastSuccess(this.files[propId].name.concat(", ").concat(response.data.message));
            this.showOverlay = false;
          })
          .catch((error) => {
            this.showOverlay = false;
            this.toastError(error, "Unable to upload file.")
          });
      }
    },

    downloadFile(file, propName) {
      const linkSource = `data:application/pdf;base64,${file}`;
      const downloadLink = document.createElement("a");
      const fileName = propName.trim().concat(".txt");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    dependsOnOtherProp(dependsOn, configName, dependentProp) {
      if (dependsOn) {
        const regex = /(?<=_)(.+)/;
        const match = dependentProp.propTypeValidationFormat.match(regex);
        if (match) {
          const originPropName = match[0];
          const answer = this.searchPropNameValue(configName, originPropName, dependentProp.propName);
          return answer;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    searchPropNameValue(configName, propName, dependentPropName) {
      const config = this.productInstanceTmp.productInstanceConfiguration.configurations.find(variant => variant.name === configName);
      const definingProp = config['productVariantPropsGroups'][configName].find(prop => prop.propName === propName);
      let isDefiningValue;
      if (definingProp['propValue'] === 'false') {
        isDefiningValue = false;
      } else if (definingProp['propValue'] === 'true') {
        isDefiningValue = true;
      } else {
        isDefiningValue = definingProp['propValue'];
      }
      if (isDefiningValue) {
        config['productVariantPropsGroups'][config.name].find(prop => prop.propName === dependentPropName)['propValue'] = config['productVariantProps'].find(prop => prop.propName === dependentPropName)['propValue'];
      }
      return isDefiningValue;
    },

  },
  watch: {
    showConfigurations(visible) {
      this.activeGroups = [];
      if (this.productInstanceTmp.product !== null && this.productInstanceTmp.product !== undefined) {
        this.productIdTmp = this.productInstanceTmp.product.productId;
        this.productTypeNameTmp = this.productInstanceTmp.product.adapterType.adapterTypeName;
      }
      if (visible) {
        const configs = this.productInstanceTmp.productInstanceConfiguration.configurations.filter(variantG => Object.entries(variantG.productVariantPropsGroups).length <= 1);
        const configGroups = this.productInstanceTmp.productInstanceConfiguration.configurations.filter(variantG => Object.entries(variantG.productVariantPropsGroups).length > 1);
        configs.flatMap(element => Object.entries(element.productVariantPropsGroups))
          .forEach((variant) => {
            this.activeGroups[variant[0].split(" ").join("_")] = { "name": variant[0] , "active": true };
          })
        configGroups.flatMap(element => Object.entries(element.productVariantPropsGroups))
          .forEach((variant) => {
            this.activeGroups[variant[0].split(" ").join("_")] = { "name": variant[0] , "active": !this.propsAreEmpty(variant[1]) };
          })
      
        this.defineRules();
        this.validateLicense()
      }
    }
  },
  data() {
    const inputType = Object.freeze({
      Textbox: "text",
      Checkbox: "checkbox",
      ReadOnlyText: "text",
      IntervalTime: "phoenix-we",
      File: "file",
      Password: 'password',
      Date: "date",
      Time: "time",
      Select: "select"
    });

    const timeHHMMRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    const timeHHMMRule = (value) => timeHHMMRegex.test(value);

    const alphaSpacesRegex =  /^[A-Za-z ]+$/;
    const alphaSpacesRule = (value) => alphaSpacesRegex.test(value);

    const rulesType = Object.freeze({
      alpha: alpha,
      alphaSpaces: alphaSpacesRule,
      numeric: numeric,
      integer: integer, 
      alphaNum: alphaNum,
      email: email,
      ipAddress: ipAddress,
      url: url,
      isoDateTime: alphaNum,
      timeHHMM: timeHHMMRule, 
      timeHHmm: alphaNum, 
      timeHHMM2: alphaNum,
      isoDateTime2: alphaNum,
      password: alphaNum,
      dependsOn: alpha,
    }); 
    function dynamicSort(property) {
      return (a, b) => {
        return a[property] < b[property]
          ? -1
          : a[property] > b[property]
            ? 1
            : 0;
      };
    }
    const files = [];
    return {
      files,
      showOverlay: false,
      errorMessages: [],
      businessErrorMessages: [],
      inputType,
      rulesType,
      showErrors: false,
      showBusinessErrors: false,
      tabIndex: 0,
      tab: null,
      dynamicSort,
      showUpdateButton: true,
      allowUpdateButton: true,
      adapterConfig: {
      },
      rules: {
      },
      activeGroups: {
      },
      validate: null,
    };
  },
  components: { AddEditDeleteButtons, ToastSubmit, InputErrorWrapper, AdaptifyDialogAlert, VueTimepicker, CiscoButton, AdaptifyOverlay },
};
</script>
<style>
:root {
  --v-theme-overlay-multiplier: 3;
}
</style>
<style scoped>
.v-tab-edit {
  height: 20px;
  color: #65bbe3;
  background-color: white;
  font-size: medium; 
  border: 2rem;
}
.v-toolbar {
  color: white;
  background-color: #64bbe3;
}
.v-tab-edit:hover {
  height: 40px;
  color: #65bbe3;
  background-color: white;
}
.checkbox__input__disabled {
   background-color: grey !important;
   border-color: gray !important;
}
.checkbox__label__disabled {
   color: gray !important;
}
input[type=file]::file-selector-button {
    border: 1px solid #64bbe3;
    color: #64bbe3;
    border-radius: .125rem;
    background-color: transparent;
    font-weight: 500;
    margin-left: 2rem;
    margin-right: 2rem;
} 
input[type=file]::file-selector-button:hover {
    opacity: 0.4;
    background-color: #e1f1f6;
}
input[type=file] {
    font-weight: 100; 
    line-height: 1.4rem; 
    text-decoration: underline;
    min-width: 15rem;
}
.type_file_card {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    padding: 1rem;
}
.type_file_card_input {
    border: none !important;
    min-width: 50rem !important;
    padding: 5px !important;
}
a {
    color: #64bbe3 !important;
}
a:hover {
    text-decoration: none !important;
    opacity: 0.4
}
</style>
 