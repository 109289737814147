<template>
  <DialogWrapper
    :show="showModalAddEditUser"
    :header="`${userEdit ? 'Edit' : 'Add'} User`">
    <AdaptifyOverlay :showOverlay="this.showOverlay"/>
    <ToastSubmit
      :toastData="toast"
      v-if="toast.showToast" />
    <span class="row">
      <span class="col-6">
        <InputErrorWrapper
          property="firstName"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="user.firstName"
              :disabled="userEdit ? true : false" />
            <label>First Name</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-6">
        <InputErrorWrapper
          property="lastName"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="user.lastName"
              :disabled="userEdit ? true : false" />
            <label>Last Name</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <InputErrorWrapper
      v-if="!userEdit"
      property="username"
      :validate="validate">
      <div class="form-group__text">
        <input
          type="text"
          v-model="user.username" />
        <label>Username</label>
      </div>
    </InputErrorWrapper>
    <span class="row">
      <span class="col-9">
        <InputErrorWrapper
          property="roles"
          :validate="validate">
          <div class="form-group__text">
            <div class="subheader">Roles</div>
            <div
              v-for="(role, index) in roles"
              :key="index"
              class="form-group base-margin-bottom">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :value="role.name"
                  v-model="user.roles" />
                <span class="checkbox__input" />
                <span class="checkbox__label">{{ role.displayName }}</span>
              </label>
            </div>
          </div>
        </InputErrorWrapper>
      </span>
      <div class="col-3 flex flex-right">
        <div>
          <div class="subheader">Active</div>
          <label class="switch">
            <input
              type="checkbox"
              v-model="user.enabled" />
            <span class="switch__input" />
          </label>
        </div>
      </div>
    </span>
    <AddEditDeleteButtons
      type="User"
      :dataEdit="userEdit"
      :add="() => handleSubmit(addUser)"
      :update="() => handleSubmit(updateUser)" />
  </DialogWrapper>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ToastSubmit from './ToastSubmit.vue';
import DialogWrapper from './DialogWrapper.vue';
import InputErrorWrapper from './InputErrorWrapper.vue';
import AddEditDeleteButtons from './AddEditDeleteButtons.vue';
import { required, email, alpha } from '@vuelidate/validators';
import AdaptifyOverlay from '../../commons/AdaptifyOverlay.vue';
import { getCookieValue } from '@/auth/Functions';
import { UserCookieName } from '@/auth/KCConfig';

export default {
  props: {
    userEdit: Object,
    organizationId: String,
    showModalAddEditUser: Boolean,
  },
  data() {
    return {
      showOverlay: false,
      user: {
        username: null,
        email: null,
        firstName: null,
        lastName: null,
        organizationId: null,
        enabled: true,
        roles: [],
      },
      rules: {
        username: { required, email },
        firstName: { required },
        lastName: { required },
        roles: { required },
      },
      roles: [],
      validate: null,
    };
  },
  async created() {
    this.getRoles();
    if (this.userEdit !== null) {
      this.user = this.userEdit;
    }
  },
  methods: {
    async getRoles() {
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/user/roles`)
        .then((response) => {
          this.roles = response.data.filter(item => item.organizationName === "customer" && item.name !== "CUSTOMER_CLIENT_CONTROLLER");
        })
        .catch((error) => this.toastError(error, 'Unable to get roles.'));
    },
    async addUser() {
      this.showOverlay = true;
      this.toast.showToast = false;
      this.user.email = this.user.username;
      this.user.organizationId = this.organizationId;
      const body = this.user;
      this.$http
        .post(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/user`,
          body
        )
        .then((response) => {
          this.user.password = response.data.tempPassword;
          this.toastSuccess('User added successfully!');
          this.showOverlay = false;
        })
        .catch((error) => {
          this.toastError(error,`${error.response.data.message}. Server responded with: `);
          this.showOverlay = false;
        });
    },
    async updateUser() {
      this.showOverlay = true;
      this.toast.showToast = false;
      const body = this.clone(this.user);
      ['id', 'createdAt', 'username', 'email', 'organizationId', 'tempPassword'].forEach(
        (field) => delete body[field]
      );
      this.$http
        .patch(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/user/${this.userEdit.id}`,
          body
        )
        .then(() => {
          this.toastSuccess('User updated successfully!');
          this.showOverlay = false;
        })
        .catch((error) => {
          this.toastError(error, 'Unable to update user.')
          this.showOverlay = false;
        });
    },
    async handleSubmit(apiCall) {
      const v$ = useVuelidate(this.rules, this.user);
      const result = await v$.value.$validate();
      this.validate = v$;
      if (result) {
        apiCall();
      }
    },
    handleClose() {
      this.$emit('refresh');
      this.$emit('update', false);
    },
  },
  components: {
    ToastSubmit,
    DialogWrapper,
    InputErrorWrapper,
    AddEditDeleteButtons,
    AdaptifyOverlay
  },
};
</script>
