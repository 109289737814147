<template>
  <CustomerHeader/>
  <div class="content contnet --alt">
    <div className="content">
      <div className="container-fluid">
        <div className="section">
          <div className="flex-center-vertical">
            <AdaptifyAlert
              type="confirm"
              :message="message"
              ref="modal"
              :modal="modal"
            />
            <h1>Instances</h1>
            <div className="flex-fluid text-right">
              <p className="lead hidden-sm-down">Showing 1-12 of 192 Devices</p>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="responsive-table">
            <table className="table table--lined table--selectable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Hostname</th>
                  <th>Site</th>
                  <th>Environment</th>
                  <th>Start/Stop</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(instance, index) in instances" :key="index">
                  <td @click="instance">{{ instance.name }}</td>
                  <td @click="instance">{{ instance.status }}</td>
                  <td @click="instance">{{ instance.hostname }}</td>
                  <td @click="instance">{{ instance.site }}</td>
                  <td @click="instance">{{ instance.environment }}</td>
                  <td>
                    <label class="switch" v-if="!instance.canBeConfigured">
                      <input
                        type="checkbox"
                        :checked="instance.isStarted"
                        @click.prevent="showNotifications()"
                      />
                      <span class="switch__input"></span>
                    </label>
                    <span
                      v-if="instance.isStarted && !instance.canBeConfigured"
                    >
                      Running</span
                    >
                    <span
                      v-if="!instance.isStarted && !instance.canBeConfigured"
                    >
                      <font color="red">Stopped</font>
                    </span>
                    <div class="col-md-6" v-if="instance.canBeConfigured">
                      <div class="steps steps--dot">
                        <div class="step visited">
                          <div class="step__icon"></div>
                          <div class="step__label">Gateway</div>
                        </div>
                        <div class="step active">
                          <div class="step__icon"></div>
                          <div class="step__label">Aspect</div>
                        </div>
                        <div class="step">
                          <div class="step__icon"></div>
                          <div class="step__label">License</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td @click="editConfig" v-if="!instance.isStarted">
                    <span class="icon-configurations icon-size-24"></span>
                  </td>
                  <td @click="editConfig" v-if="instance.isStarted"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <ul className="pagination pull-right">
            <li>
              <a className="disabled" href="javascript:;"
                ><span className="icon-chevron-left-double"></span
              ></a>
            </li>
            <li>
              <a className="disabled" href="javascript:;"
                ><span className="icon-chevron-left"></span
              ></a>
            </li>
            <li className="active"><a href="javascript:;">1</a></li>
            <li><a href="javascript:;">2</a></li>
            <li><a href="javascript:;">3</a></li>
            <li><a href="javascript:;">4</a></li>
            <li><span className="icon-more"></span></li>
            <li><a href="javascript:;">16</a></li>
            <li>
              <a href="javascript:;"
                ><span className="icon-chevron-right"></span
              ></a>
            </li>
            <li>
              <a href="javascript:;"
                ><span className="icon-chevron-right-double"></span
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import CustomerHeader from '@/components/CustomerHeader.vue';
import AdaptifyAlert from '@/components/commons/AdaptifyAlert.vue';
import { getCookieValue, deleteCookie } from '@/auth/Functions';
import { UserCookieName, CookieName } from '@/auth/KCConfig';

export default {
  setup() {
    const router = useRouter();
    function configuration() {
      router.push('/configuration');
    }
    function editConfig() {
      router.push('/editConfiguration');
    }
    return {
      configuration,
      editConfig,
    };
  },
  data() {
    return {
      message: 'Are you sure?',
      currentUser: null,
      organizationId: null,
      instances: [
        {
          name: 'Historical Adapter',
          status: 'online',
          hostname: 'Work_PC',
          site: 'NJ',
          environment: 'A',
          isStarted: true,
          canBeConfigured: false,
        },
        {
          name: 'RTA Gateway Adapter',
          status: 'online',
          hostname: 'Work_PC',
          site: 'NJ',
          environment: 'A',
          isStarted: false,
          canBeConfigured: false,
        },
        {
          name: 'RTA Gateway Adapter',
          status: 'online',
          hostname: 'Work_PC',
          site: 'NJ',
          environment: 'A',
          isStarted: true,
          canBeConfigured: false,
        },
        {
          name: 'Historical Adapter',
          status: 'online',
          hostname: 'Work_PC',
          site: 'NJ',
          environment: 'A',
          isStarted: true,
          canBeConfigured: false,
        },
      ],
    };
  },
  name: 'AdapterInstances',
  components: {
    CustomerHeader,
    AdaptifyAlert,
  },
  methods: {
    showNotifications() {
      this.$refs.modal.handleModal();
    },
    getCurrentUser() {
      const userData = JSON.parse(getCookieValue(UserCookieName));
      const userEmail = userData.email;
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/user/me`, {
          headers: {
            'c5-userId': userEmail
          }
        })
        .then((c5User) => {
          this.currentUser = c5User.data;
          this.organizationId = c5User.data.organizationId;
        })
        .catch(error => {
          console.error(`Unable to C5 user data`, error);
          getCookieValue(CookieName) ? deleteCookie(CookieName) : "";
          getCookieValue(UserCookieName) ? deleteCookie(UserCookieName) : "";
          this.$router.push('/');
        });
    },
  },
};
</script>
