<template>
  <div class="d-flex justify-content-center align-items-start home-container">
    <div class="flex flex-center">
      <SpinLoader v-if="loading"/>
      <img v-else src="../../public/c5_logo.png" alt="Logo">
    </div>
    <div class="flex flex-center alert-container">
      <AdaptifyAlert v-if="!loading && !isRedirecting" type="warning" :message="`User not registrated in C5 please contact your tool administrator`" :animate="true"/>
      <AdaptifyAlert v-if="!loading && isRedirecting" type="info" :message="`Welcome, you're being redirected`" :animate="true"/>
    </div>
    <CiscoButton
        v-if="!loading && !isRedirecting"
        color="blue"
        label="Retry login"
        :isDisabled=false
        :action="() => this.goBack()"/>
  </div>
</template>

<script>
import { deleteCookie, getCookieValue, setCookieValue } from '@/auth/Functions';
import { CookieName, UserCookieName } from '@/auth/KCConfig';
import SpinLoader from '@/components/commons/SpinLoader.vue';
import AdaptifyAlert from '@/components/commons/AdaptifyAlert.vue';
import CiscoButton from '@/components/commons/CiscoButton.vue';

export default {
  async created() {
    this.getCurrentUser()
  },
  data() {
    return {
      loading: true,
      isRedirecting: null,
    };
  },
  methods: {
    async goBack() {
      this.loading = true;
      this.$oktaAuth.signOut();
      getCookieValue(CookieName) ? deleteCookie(CookieName) : "";
      getCookieValue(UserCookieName) ? deleteCookie(UserCookieName) : "";
      this.$router.push('/');
    },
    getCurrentUser() {
      this.$oktaAuth.getUser()
        .then(userData => {
          this.$http
            .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/user/me`, {
              headers: {
                'c5-userId': userData.email
              }
            })
            .then((c5User) => {
              this.loading = false;
              if (c5User.data.roles.includes(this.config.ROLE_CISCO_ADMINISTRATOR)) {
                this.isRedirecting = true;
                setCookieValue(UserCookieName, JSON.stringify({ 'email': userData.email, 'roles': this.config.ROLE_CISCO_ADMINISTRATOR }));
                this.$router.push('/adminhome');
              } else if (c5User.data.roles.includes(this.config.ROLE_ADMINISTRATOR)) {
                this.isRedirecting = true;
                setCookieValue(UserCookieName, JSON.stringify({ 'email': userData.email, 'roles': this.config.ROLE_ADMINISTRATOR }));
                this.$router.push('/customerhome');
              } else {
                this.isRedirecting = true;
                setCookieValue(UserCookieName, JSON.stringify({ 'email': userData.email, 'roles': c5User.data.roles }));
                this.$router.push(`/customer/${c5User.data.organizationId}`);
              }
            })
            .catch(error => {
              this.loading = false;
              this.isRedirecting = false;
              console.error(`Unable to C5 user data`, error);
            });
        })
        .catch(error => {
          this.loading = false;
          this.isRedirecting = false;
          console.error('Error getting authenticated user info: ', error);
        });
    },
  },
  name: 'HomePage',
  components: {
    SpinLoader,
    AdaptifyAlert,
    CiscoButton
  }
};
</script>
<style scoped>
.home-container {
  height: 100vh;
  background-image: url('@/assets/background.png');
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 5rem;
}
.alert-container{
  margin-top: 5rem;
}
</style>
