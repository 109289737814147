export const isUserAuthenticated = () => {
  return getCookieValue('adaptify_keycloak_Auth') ? true : false;
};

export const getCookieValue = (cookiename) => {
  const match = document.cookie.match(new RegExp(`(^| )${cookiename}=([^;]+)`));
  return match ? match[2] : false;
};

export const setCookieValue = (cookiename, value) => {
  const date = new Date();
  const daysToExpire = 1;
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  document.cookie = `${cookiename}=${value}; expires=${date.toGMTString()}; path=/`;
};

export const deleteCookie = (cookiename) => {
  const date = new Date();
  date.setTime(date.getTime() - 1);
  document.cookie = `${cookiename}=; expires=${date.toGMTString()}; path=/`;
};

export const parseToken = function(token) {
  if (token === '') {
    return null;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const authObject = decodeURIComponent(
    atob(base64)
      .split('.')
      .map((c) => {
        return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
      })
      .join('')
  );
  return JSON.parse(authObject);
};
