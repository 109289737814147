<template>
  <CustomerHeader/>
  <div className="container-fluid">
    <div v-if="!this.organizationId" class="flex flex-center">
      <SpinLoader/>
    </div>
    <AdaptersBox v-if="this.organizationId" v-bind:organizationId="organizationId" />
    <UserBox v-if="this.currentUser" v-bind:userRoles="this.currentUser.roles" v-bind:organization-id="this.organizationId" v-bind:isCiscoOrganization="false"/>
  </div>
</template>

<script>
import '../assets/css/adaptifytables.css';
import 'vue3-easy-data-table/dist/style.css';
import UserBox from '@/components/databoxes/UserBox.vue';
import CustomerHeader from '../components/CustomerHeader.vue';
import AdaptersBox from '@/components/databoxes/AdaptersBox.vue';
import SpinLoader from '@/components/commons/SpinLoader.vue';
import { getCookieValue, deleteCookie } from '@/auth/Functions';
import { UserCookieName, CookieName } from '@/auth/KCConfig';

export default {
  data() {
    return {
      currentUser: null,
      organizationId: null,
    };
  },
  async created() {
    await this.getCurrentUser();
  },
  methods: {
    getCurrentUser() {
      const userData = JSON.parse(getCookieValue(UserCookieName));
      const userEmail = userData.email;
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/user/me`, {
          headers: {
            'c5-userId': userEmail
          }
        })
        .then((c5User) => {
          this.currentUser = c5User.data;
          this.organizationId = c5User.data.organizationId;
          this.getCustomer();
        })
        .catch(error => {
          console.error(`Unable to C5 user data`, error);
          getCookieValue(CookieName) ? deleteCookie(CookieName) : "";
          getCookieValue(UserCookieName) ? deleteCookie(UserCookieName) : "";
          this.$router.push('/');
        });
    },
    getCustomer() {
      this.loading = true;
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.customer = response.data;
            this.contacts = response.data.contacts;
          } else {
            this.error = `Unable to display customers. Server responded with "${response.status}"`;
            console.log(this.error);
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.error = `Unable to display customer. Server responded with "${e.message}"`;
          console.error(e);
        });
    },
  },
  components: {
    UserBox,
    AdaptersBox,
    CustomerHeader,
    SpinLoader
  },
};
</script>
