<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AdminNav/>
  <div class="content --alt">
    <div className="container-fluid">
      <div v-if="!this.organizationId" class="flex flex-center">
        <SpinLoader/>
      </div>
      <CustomerBox v-if="this.organizationId" v-bind:organizationId="organizationId" />
    </div>
  </div>
</template>

<script>
import AdminNav from '../components/AdminHeader.vue';
import CustomerBox from '../components/databoxes/CustomerBox.vue';
import SpinLoader from '@/components/commons/SpinLoader.vue';
import { getCookieValue, deleteCookie } from '@/auth/Functions';
import { UserCookieName, CookieName } from '@/auth/KCConfig';

export default {
  data() {
    return {
      loading: false,
      org: {},
      currentUser: null,
      organizationId: null,
    };
  },
  async created() {
    await this.getCurrentUser();
  },
  methods: {
    getCurrentUser() {
      const userData = JSON.parse(getCookieValue(UserCookieName));
      const userEmail = userData.email;
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/user/me`, {
          headers: {
            'c5-userId': userEmail
          }
        })
        .then((c5User) => {
          if (c5User.status == 200) {
            this.currentUser = c5User.data;
            this.organizationId = c5User.data.organizationId;
          } else {
            this.error = `Unable to display adapters. Server responded with "${c5User.status}"`;
            console.error(this.error);
          }
        })
        .finally(() => {
          this.isShowLoadingProducts = false;
        })
        .catch(error => {
          console.error(`Unable to C5 user data`, error);
          getCookieValue(CookieName) ? deleteCookie(CookieName) : "";
          getCookieValue(UserCookieName) ? deleteCookie(UserCookieName) : "";
          this.$router.push('/');
        });
    },
  },
  components: {
    AdminNav,
    CustomerBox,
    SpinLoader
  },
};
</script>
