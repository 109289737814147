<template>
  <div class="splash-container">
    <div class="content">
      <div class="logo">
        <img src="../../public/c5_logo.png" alt="Logo">
      </div>
      <h1>Cisco Contact Center Cloud Connector</h1>
      <CiscoButton
        color="blue"
        label="Login"
        :isDisabled=false
        :action="() => login()"/>
    </div>
  </div>
</template>

<script>
import CiscoButton from '../components/commons/CiscoButton.vue';
import { setCookieValue } from '@/auth/Functions';
import { CookieName } from '@/auth/KCConfig';
export default {
  async created() {
    if (this.$oktaAuth.isLoginRedirect()) {
      this.$oktaAuth.handleLoginRedirect()
        .then(() => {
          this.$oktaAuth.tokenManager.getTokens()
            .then(tokens => {
              const { accessToken } = tokens;
              this.$http.defaults.headers.Authorization = `Bearer ${accessToken.accessToken}`;
              setCookieValue(CookieName, accessToken.accessToken)
              this.$router.push('/home');
            })
            .catch(err => {
              console.error('Error parse token from url', err);
            });
        })
        .catch(err => {
          console.error('Error handling login redirect', err);
        });
    } else {
      const isAuthenticated = await this.$oktaAuth.isAuthenticated();
      if (isAuthenticated) {
        this.$router.push('/home');
      }
    }
  },
  name: 'SplashPage',
  methods: {
    async login() {
      console.log("Login triggered")
      this.$oktaAuth.token.getWithRedirect({
        responseType: ['id_token']
      });
    }
  },
  components: {
    CiscoButton
  }
}
</script>
<style scoped>
.splash-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('@/assets/background.png');
  background-size: cover;
  background-position: center;
}

.logo img {
  max-width: 50rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.content {
  text-align: center;
  color: #0d274d;;
  font-family: var(--cui-font-stack);
}

</style>
